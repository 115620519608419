.quizz-surrounding {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    border-width: 10px;
    border: solid #4f4f4f;
    border-radius: 10px;
    width: 400px;
    padding: 20px;

}