/* Success and error classes */

.list-group-item.success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.list-group-item.error {
  background-color: #f2dede;
  border-color: #ebccd1;
}

.list-group-item {
    cursor: pointer;
}



